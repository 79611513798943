/*
 *  @author       :  Prashant Kapoor
 *  @license      :  MIT
 *  @created      :  04/09/2021
 *  @lastModified :  04/09/2021
 *  @modifiedBy   :  Mukesh Majoka
 *  @type         :  service
 */
import React from "react";
import * as opsService from "./Ops";
import * as dataConstants from "../constants/Data";
import { toast } from "react-toastify";
import { creditScoreData } from "../constants/Enums";
import { getLoggedInUserData } from "./User";
import { accessMatrix } from "../constants/Enums";
const en = require("../language/en.json");
let language = {
  en,
};
const checkAction = (action, access, userData = false, allowedAll = false) => {
    let allowed = false;
    if (!userData) userData = getLoggedInUserData();
    // console.log("#####userData", userData);
    if (userData.role_id === 2) {
      allowed = true;
    } else if (localStorage.accessAction) {
      JSON.parse(localStorage.accessAction).forEach((e) => {
        if ((e.title === action && e[access] === 1) || allowedAll)
          allowed = true;
      });
    }
    return allowed;
  },
  setActiveLanguage = (language) => {
    localStorage.setItem("activeLanguage", language);
  },
  getActiveLanguage = () => {
    return localStorage.getItem("activeLanguage")
      ? localStorage.getItem("activeLanguage")
      : "en";
  },
  translate = (key) => {
    return language[getActiveLanguage()][key];
  },
  validateCompanyName = (c1, c2) => {
    c1 = slugify(c1)
      .replace("private-limited", "pvt-ltd")
      .replace("public-limited", "pub-ltd")
      .replace("limited", "ltd");
    c2 = slugify(c2)
      .replace("private-limited", "pvt-ltd")
      .replace("public-limited", "pub-ltd")
      .replace("limited", "ltd");
    return c1 === c2;
  },
  checkAccessRoute = (url, userData = false) => {
    if (!userData) userData = getLoggedInUserData();
    return checkAction(
      accessMatrix[url]?.action,
      accessMatrix[url]?.access,
      userData,
      accessMatrix[url]?.allowedAll
    );
  },
  sendMail = async (to, subject, content) => {
    const formData = new FormData();
    formData.append("to", to);
    formData.append("subject", subject);
    formData.append("content", content);
    return await opsService.post(
      dataConstants.base.api + "utils/sendmail",
      formData
    );
  },
  sendAppShare = async (data) => {
    const formData = new FormData();
    formData.append("to", data.to);
    formData.append("type", data.type);
    return await opsService.post(
      dataConstants.base.api + "utils/send-app-share",
      formData
    );
  },
  isValidDate = (d) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d.getTime())) return false;
      else return true;
    } else return false;
  },
  getContactRequest = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("message", data.message);
    return await opsService.post(
      dataConstants.base.api + "data/get-contact-request",
      formData
    );
  },
  getPreventiveCheckList = async () => {
    return await opsService.get(
      dataConstants.base.api + "data/getpreventivechecklist"
    );
  },
  getStateCityList = async () => {
    return await opsService.get(
      dataConstants.base.api + "data/getStatesCities"
    );
  },
  parseToHtml = (unparsedHtml) => {
    let parsedHtml = unparsedHtml
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&cent;/g, "¢")
      .replace(/&pound;/g, "£")
      .replace(/&yen;/g, "¥")
      .replace(/&euro;/g, "€")
      .replace(/&copy;/g, "©")
      .replace(/&reg;/g, "®");
    return parsedHtml;
  },
  renderRatingSystem = (rating) => {
    return (
      <>
        {rating === 5 ? (
          <>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
          </>
        ) : rating === 4 ? (
          <>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
          </>
        ) : rating === 3 ? (
          <>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
          </>
        ) : rating === 2 ? (
          <>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
          </>
        ) : rating === 1 ? (
          <>
            <ion-icon name="star" class="orange"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
          </>
        ) : (
          <>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
            <ion-icon name="star-outline"></ion-icon>
          </>
        )}
      </>
    );
  },
  isEmpty = (data) => {
    return ["", null, {}].includes(data);
  },
  isValidJson = (e) => {
    try {
      JSON.parse(e);
      return true;
    } catch (e) {
      return false;
    }
  },
  isValidEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  },
  isValidMobile = (mobile) => {
    return /^[6,7,8,9]\d{9}$/.test(mobile);
  },
  showpreloader = () => {
    document.getElementById("preloader").style.display = "block";
  },
  hidepreloader = () => {
    document.getElementById("preloader").style.display = "none";
  },
  openModal = (modalId) => {
    document.getElementsByTagName("body")[0].classList.add("modal-open");
    if (document.getElementById(modalId))
      document.getElementById(modalId).classList.add("open");
  },
  slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, "");
    str = str.toLowerCase();
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;",
      to = "aaaaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
    str = str
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
    return str;
  },
  closeModal = (modalId) => {
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
    document.getElementById(modalId).classList.remove("open");
  },
  changeFormatEmail = (emial) => {
    const split = emial.split("@");
    let lastChar = split[0].charAt(split[0].length - 1);
    let secLastChar = split[0].charAt(split[0].length - 2);
    let str = split[0].slice(0, -2);
    let string = "x";
    for (let i = 0; i < str.length - 1; i++) {
      string = string.concat("x");
    }
    string = string.concat(secLastChar);
    string = string.concat(lastChar);
    string = string.concat("@");
    string = string.concat(split[1]);

    return string;
  },
  changeMobileFormat = (mobile) => {
    console.log("mobilemobile", mobile);
    let lastChar = mobile.charAt(mobile.length - 1);
    let secLastChar = mobile.charAt(mobile.length - 2);
    let string = "x";
    for (let i = 0; i < 7; i++) {
      string = string.concat("x");
    }
    string = string.concat(secLastChar);
    string = string.concat(lastChar);
    return string;
  },
  showAlert = (params, json = false) => {
    if (Array.isArray(params.msg)) params.msg = params.msg.join("\n");
    switch (params.type) {
      case "success":
        toast.success(params.msg, json);
        break;
      case "error":
        toast.error(params.msg, json);
        break;
      case "info":
        toast.info(params.msg, json);
        break;
      case "warning":
        toast.warning(params.msg, json);
        break;
      case "custom":
        toast(params.msg, json);
        break;
    }
  },
  fadeAlert = (alert) => {
    if (alert.style.opacity > 0) {
      setTimeout(() => {
        alert.style.opacity -= 0.1;
        fadeAlert(alert);
      }, 50);
    } else {
      alert.remove();
    }
  },
  blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  },
  getMonthName = (m) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return month[m];
  },
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  getFormattedDate = (date) => {
    // console.log("dategetFormattedDate",date)
    if (typeof date !== "date") {
      if (typeof date !== "number") date = Number(date);
      date = new Date(date);
    }
    let d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
      y = date.getFullYear();
    return String(d + ", " + getMonthName(date.getMonth()) + ", " + y);
  },
  getFormattedTime = (date) => {
    if (typeof date !== "date") {
      if (typeof date !== "number") date = Number(date);
      date = new Date(date);
    }
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    let amPm = hours >= 12 ? "PM" : "AM",
      timeString =
        pad(hours, "hour") +
        " : " +
        pad(minutes) +
        " : " +
        pad(seconds) +
        " " +
        amPm;
    return timeString;
  },
  pad = (num, type = "") => {
    if (type === "hour") num = num > 12 ? num - 12 : num;
    return ("0" + num).slice(-2);
  },
  getDashboardData = async (fromDate, toDate, token) => {
    return await opsService.get(
      dataConstants.base.api +
        "data/getdashboarddata/" +
        fromDate +
        "/" +
        toDate,
      token
    );
  },
  getCustomerPlan = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/customer-plan",
      data,
      token
    );
  },
  getConsentText = async (token) => {
    return await opsService.get(
      dataConstants.base.api + "data/consent-text",
      token
    );
  },
  getBusinessData = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/business-data",
      data,
      token
    );
  },
  getRevenueData = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/revenue-data",
      data,
      token
    );
  },
  getVoucherstatus = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/voucher-status",
      data,
      token
    );
  },
  getTallyRegisteredBusiness = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/tally-registered-data",
      data,
      token
    );
  },
  getCirData = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/cir-data",
      data,
      token
    );
  },
  getDefaulterData = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/defaulter-data",
      data,
      token
    );
  },
  getInitiateBusinessByBuyer = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/business-initiate/buyer",
      data,
      token
    );
  },
  getInitiateBusinessBySupplier = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "data/business-initiate/supplier",
      data,
      token
    );
  },
  toggleActiveClass = (id) => {
    document.getElementById(id).classList.toggle("active");
  },
  getDay = (num) => {
    let daysList = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysList[num];
  },
  verifyAadhar = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "settlement/verify-aadhar",
      data,
      token
    );
  },
  verifyGst = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + "settlement/verify-gst",
      data,
      token
    );
  },
  parseAddress = (addressObject) => {
    if (addressObject !== "undefined" && addressObject) {
      let activeSocietyTitle = "",
        activeSociety = "";
      if (
        addressObject.activeSociety !== null &&
        addressObject.activeSociety &&
        addressObject.activeSociety !== "undefined" &&
        addressObject.activeSociety !== "[object Object]" &&
        addressObject.activeSociety !== "null" &&
        addressObject.activeSociety !== null
      ) {
        activeSociety = JSON.parse(addressObject.activeSociety);
        if (activeSociety !== null) {
          activeSocietyTitle = activeSociety.title;
        }
      }
      let parsedAddress =
        addressObject.building +
        ", " +
        activeSocietyTitle +
        ", " +
        addressObject.locality +
        ", " +
        addressObject.city +
        ", " +
        addressObject.state +
        " - " +
        addressObject.pinCode;
      return parsedAddress;
    }
    return "";
  },
  catchError = (e) => {
    console.error("e catch errror", e);
    showAlert({ type: "error", msg: "error" });
    hidepreloader();
  },
  getCreditScoreStatus = (score) => {
    let finalData = null,
      found = false;
    creditScoreData.reverse().forEach((e) => {
      if (score <= e.maxScore && score >= e.minScore) {
        if (!found) finalData = e;
        found = true;
      }
    });
    return finalData;
  },
  isValidGst = (number) => {
    number = number.toUpperCase();
    return /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{4,5}[0-9]{4,5}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1})+$/.test(
      number
    );
  },
  isValidPan = (number) => {
    return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(number);
  },
  queryStringToJSON = (queryString) => {
    if (queryString.indexOf("?") > -1) {
      queryString = queryString.split("?")[1];
    }
    var pairs = queryString.split("&");
    var result = {};
    pairs.forEach(function(pair) {
      pair = pair.split("=");
      result[pair[0]] = decodeURIComponent(pair[1] || "");
    });
    return result;
  },
  getEventHooks = async (token) => {
    let result = await opsService.get(
      dataConstants.base.api + "data/eventHooks/get",
      token
    );
    return result;
  },
  defferenceBetweenDates = async (date) => {
    date = date.split(" ");
    let expiryDate = new Date(date[0].replace(/-/g, "/"));
    // console.log("new date####", expiryDate);
    var expiryYear = expiryDate.getFullYear();
    var expiryMonth = expiryDate.getMonth() + 1;
    var expiryDay = expiryDate.getDate();

    var expiryDateTime = expiryMonth + "/" + expiryDay + "/" + expiryYear;

    var now = new Date();
    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth() + 1;
    var currentDay = now.getDate();
    var currentDateTime = currentMonth + "/" + currentDay + "/" + currentYear;

    const date1 = new Date(currentDateTime);
    const date2 = new Date(expiryDateTime);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
    return diffDays;
  };

const dateDifference = (date1, date2, diffIn) => {
  try {
    const date_first = new Date(date1);
    const date_second = new Date(date2);
    let date_difference = "";
    // ✅ Get timestamp in Milliseconds
    const timestamp_first = date_first.getTime();
    const timestamp_second = date_second.getTime();

    // ✅ If you need to convert to Seconds
    const timestampSeconds_1 = Math.floor(timestamp_first / 1000);
    const timestampSeconds_2 = Math.floor(timestamp_second / 1000);
    let diffInSeconds = Math.abs(timestampSeconds_1 - timestampSeconds_2);
    switch (diffIn) {
      case "second":
        date_difference = diffInSeconds;
        break;
      case "hour":
        date_difference = Math.ceil((diffInSeconds % (3600 * 24)) / 3600);
        break;
      default:
        date_difference = Math.floor(diffInSeconds / (3600 * 24));
    }
   
    return date_difference;
  } catch (error) {
    console.log(error);
  }
};
const getDaysDifference = (dateString) => {
  console.log("dateString: ", dateString);
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  givenDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const diffTime = givenDate-currentDate ;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
   console.log("date_difference: ", diffDays);
  return diffDays;
};

export {
  getActiveLanguage,
  setActiveLanguage,
  translate,
  checkAction,
  checkAccessRoute,
  validateCompanyName,
  pad,
  getEventHooks,
  getDay,
  getFormattedDate,
  getFormattedTime,
  sendMail,
  showpreloader,
  hidepreloader,
  getCreditScoreStatus,
  showAlert,
  isValidGst,
  isValidPan,
  isValidJson,
  isValidEmail,
  isValidMobile,
  isValidDate,
  openModal,
  closeModal,
  slugify,
  blobToFile,
  getBase64,
  getMonthName,
  renderRatingSystem,
  getDashboardData,
  toggleActiveClass,
  parseToHtml,
  getPreventiveCheckList,
  sendAppShare,
  getContactRequest,
  parseAddress,
  catchError,
  getStateCityList,
  queryStringToJSON,
  getCustomerPlan,
  getBusinessData,
  getRevenueData,
  getCirData,
  getDefaulterData,
  getInitiateBusinessByBuyer,
  getInitiateBusinessBySupplier,
  changeFormatEmail,
  changeMobileFormat,
  isEmpty,
  verifyAadhar,
  verifyGst,
  getConsentText,
  defferenceBetweenDates,
  getVoucherstatus,
  getTallyRegisteredBusiness,
  dateDifference,
  getDaysDifference,
};
